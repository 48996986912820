<template>
  <div>
    <el-card class="box-card" shadow="always">
      <el-table
          :data="honorList"
          :fit="true"
          max-height="800"
          style="width: 100%">
        <el-table-column
            type="index"
            align="center"
            label="序号">
        </el-table-column>
        <el-table-column
            label="证书荣誉照片"
            align="center">
          <template slot-scope="scope">
            <img class="table-img" :src="scope.row.img">
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="desc"
            label="荣誉描述">
          <template slot-scope="scope">
            <img class="table-img" :src="scope.row.title">
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="weight"
            label="权重">
        </el-table-column>
        <el-table-column
            prop="type"
            align="center"
            label="操作">
          <template slot="header" slot-scope="scope">
            <el-button size="medium" type="primary" @click="addHonor">新增</el-button>
          </template>
          <template slot-scope="scope">
            <el-link :underline="false" type="danger" @click="delHonor(scope.row.id)">删除</el-link>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog
        title="新增"
        :visible.sync="addDialogVisible"
        width="50%">
      <el-form ref="form" :model="addForm" label-width="120px">
        <el-form-item label="图片">
          <img-upload :url="addForm.url" @change="urlChange"></img-upload>
        </el-form-item>
        <el-form-item label="描述">
          <img-upload :url="addForm.desc" @change="urlDescChange"></img-upload>
        </el-form-item>
        <el-form-item label="权重">
          <el-input type="number" v-model="addForm.weight"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button @click="submit">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import tinymce from 'tinymce/tinymce';
import Editor from '@tinymce/tinymce-vue';
import 'tinymce/themes/silver'
import 'tinymce/icons/default'

import { getHonor, addHonor, delHonor } from "@/plugins/api/company";
import ImgUpload from "@/components/ImgUpload";

export default {
  name: 'Honor',
  components: {
    ImgUpload,
    'editor': Editor
  },
  data() {
    return {
      init: {
        language_url: '/static/tinymce/lang/zh_CN.js',
        language: 'zh_CN',
        skin_url: '/static/tinymce/skin/oxide',
        height: 300,
        content_style: "p {margin: 0}",
        branding: false,
        menubar: false,
        toolbar: 'undo redo | bold fontsizeselect forecolor',
      },
      honorList: [],
      previewInfo: {},
      dialogVisible: false,
      addDialogVisible: false,
      addForm: {
        url: '',
        weight: '',
        desc: '',
      },
      fileList: []
    }
  },
  methods: {
    preview(row) {
      this.previewInfo = row;
      this.dialogVisible = true;
    },
    addHonor() {
      this.addDialogVisible = true
    },
    urlChange(url) {
      this.addForm.url = url;
    },
    urlDescChange(url) {
      this.addForm.desc = url;
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    async getHonor() {
      let res = await getHonor();
      console.log(res)
      this.honorList = res
    },
    async submit() {
      let res = await addHonor({
        title: this.addForm.desc,
        img: this.addForm.url,
        weight: this.addForm.weight,
      });
      this.addForm = {
        desc: '',
        url: '',
        weight: 10,
      }
      this.addDialogVisible = false;
      this.getHonor();
      console.log(res)
    },
    async delHonor(id) {
      let res = await delHonor({id});
      this.getHonor()
    }
  },
  created() {
    this.getHonor();
  }
}
</script>

<style scoped>
.tox .tox-edit-area__iframe {
  background-color:rgba(54, 93, 154, 0.4) !important;
}
.table-img {
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 200px;
}
.preview-img {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
}
</style>
